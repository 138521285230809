export default [
  {
    title: 'Магазин',
    route: 'shop',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'Товары',
        route: 'shop-products',
        icon: 'ShoppingBagIcon',
      },
      {
        title: 'Опции товаров',
        route: 'product-options',
        icon: 'FileTextIcon',
      },
      {
        title: 'Описания размеров',
        route: 'size-description',
        icon: 'FileTextIcon',
      },
      {
        title: 'Загрузка товаров',
        route: 'shop-upload',
        icon: 'UploadIcon',
      },
      {
        title: 'Управление категориями',
        route: 'shop-categories',
        icon: 'TabletIcon',
      },
      {
        title: 'Заказы',
        route: 'shop-orders',
        icon: 'ClipboardIcon',
      },
    ],
  },
  {
    title: 'Склад',
    icon: 'BoxIcon',
    children: [
      {
        title: 'Новые товары',
        route: 'storage-new',
        icon: 'BoxIcon',
      },
    ],
  },
  {
    title: 'Старты',
    route: 'starts',
    icon: 'StarIcon',
    children: [
      {
        title: 'Все старты',
        route: 'starts',
        icon: 'StarIcon',
      },
      {
        title: 'Календарь стартов',
        route: 'starts-calendar',
        icon: 'StarIcon',
      },
      {
        title: 'Шаблоны групп',
        route: 'starts-groups',
        icon: 'StarIcon',
      },
      {
        title: 'Список команд',
        route: 'teams',
        icon: 'StarIcon',
      },
      {
        title: 'Города',
        route: 'starts-cities',
        icon: 'StarIcon',
      },
      {
        title: 'Сезоны',
        route: 'seasons',
        icon: 'SunIcon',
      },
      {
        title: 'Альбомы',
        route: 'albums',
        icon: 'BookOpenIcon',
      },
      {
        title: 'Донаты',
        route: 'donations',
        icon: 'DollarSignIcon',
      },
    ],
  },

  {
    title: 'Клубы',
    route: 'clubs',
    icon: 'SmileIcon',
    children: [
      {
        title: 'Тренировки',
        route: 'workouts',
        icon: 'ActivityIcon',
      },
      {
        title: 'Тренеры',
        route: 'trainers',
        icon: 'UserPlusIcon',
      },
      {
        title: 'Виды спорта',
        route: 'kindsofsports',
        icon: 'ListIcon',
      },
      {
        title: 'Расписания',
        route: 'schedules',
        icon: 'CalendarIcon',
      },
      {
        title: 'Абонементы',
        route: 'subscriptions',
        icon: 'DollarSignIcon',
      },
      {
        title: 'Запросы',
        route: 'requests',
        icon: 'SendIcon',
      },
      {
        title: 'Вопросы',
        route: 'questions',
        icon: 'HelpCircleIcon',
        children: [
          {
            title: 'Вопросы',
            route: 'questions',
            icon: 'HelpCircleIcon',
          },
          {
            title: 'Категории',
            route: 'questions-categories',
            icon: 'HelpCircleIcon',
          },
        ],
      },
      {
        title: 'Настройки страницы',
        route: 'clubs-settings',
        icon: 'SettingsIcon',
      },
    ],
  },
  {
    title: 'Новости',
    route: 'news',
    icon: 'MailIcon',
    children: [
      {
        title: 'Все новости',
        route: 'news',
        icon: 'MailIcon',
      },
      {
        title: 'Категории',
        route: 'news-categories',
        icon: 'ListIcon',
      },
    ],
  },
  {
    title: 'Пользователи',
    route: 'users',
    icon: 'UsersIcon',
  },
  {
    title: 'Транзакции',
    icon: 'BoxIcon',
    children: [
      {
        title: 'Транзакции',
        route: 'transactions',
        icon: 'CreditCardIcon',
      },
      {
        title: 'Получение статистики',
        route: 'statistics',
        icon: 'BoxIcon',
      },
    ],
  },
  {
    title: 'Виртуальные платежи',
    route: 'virtual-payment',
    icon: 'BoxIcon',
    children: [
      {
        title: 'Кошельки',
        route: 'wallets',
        icon: 'CreditCardIcon',
      },
      {
        title: 'Транзакции',
        route: 'virtual-transactions',
        icon: 'CreditCardIcon',
      },
    ],
  },
  {
    title: 'Пользовательское',
    route: 'client',
    icon: 'BoxIcon',
    children: [
      {
        title: 'Публикации',
        route: 'posts',
        icon: 'BookOpenIcon',
      },
      {
        title: 'Внешние регистрации',
        route: 'external-registrations',
        icon: 'StarIcon',
      },
    ],
  },
  {
    title: 'Синхронизация',
    route: 'sync',
    icon: 'CloudIcon',
  },
]
